import React from "react"
import Layout from "../components/Layout"
import { navigate } from "gatsby"
import "../components/Shared/Auth"
import { isBrowser } from "../components/Shared/Auth"

export default () => (
  <Layout>
    {isBrowser ?
      navigate("/app/home") :
      null
    }
  </Layout>
)